
import { ENVIRONMENT } from "src/scenes/App";

//const notStartedYet = () => false; // commented out just in case more features get added in this list
const notProductionReady = () => ENVIRONMENT.get() !== ENVIRONMENT.PRODUCTION;

export const IsFeatureReady = {
    signUpButton: notProductionReady,
    chat: notProductionReady,
    chatUserOnline: notProductionReady,
    interactionRateStat: notProductionReady,
    surveyRedesign: notProductionReady,
    noticeboardAttachments: notProductionReady,
    clientQuoteCategorySelection: notProductionReady,
    perks: notProductionReady
};
