import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { PageContainer } from "src/components";
import TablePage from "./TablePage";
import usePerks from "./hooks/usePerks";
import FormPage from "./FormPage";
import { DEFAULT_FORM_STATE, USER_GROUP, PERK_TYPE } from "src/constants/perks";

const Perk = ({ user }) => {
    const [formData, setFormData] = useState(DEFAULT_FORM_STATE);
    const [updatingUuid, setUpdatingUuid] = useState(null);
    const { perks, setPerks, isFetching } = usePerks(user.token);
    const [allPerksChecked, setAllPerksChecked] = useState(false);
    const [checkedPerks, setCheckedPerks] = useState([]);
    const [isFormVisible, setIsFormVisible] = useState(false);

    const handleCheckAllPerks = () => {
        const checked = !allPerksChecked;
        setAllPerksChecked(checked);
        const checkedPerks = checked ? perks.map(perk => perk.uuid) : [];
        setCheckedPerks(checkedPerks);
    };

    const handleCheckPerk = (e, perkUuid) => {
        const { checked } = e.target;
        setCheckedPerks(prevCheckedPerks => {
            if (checked) {
                return [...prevCheckedPerks, perkUuid];
            } else {
                return prevCheckedPerks.filter(uuid => uuid !== perkUuid);
            }
        });
    };

    const handleFormVisible = () => {
        setUpdatingUuid(null);
        setIsFormVisible(!isFormVisible);
        setFormData(DEFAULT_FORM_STATE);
    };

    const handleChangePerkType = (perkType) => {
        if (perkType === PERK_TYPE.GENERAL) {
            setFormData({
                ...formData,
                type: perkType,
                selectedCompanyRecipients: []
            })
        } else if (perkType === PERK_TYPE.GIFT) {
            setFormData({
                ...formData,
                type: perkType,
                allEmployeesInPerk: false,
                allClientsInPerk: false
            });
        }
    };

    const handleInputChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => {
            return { ...prevFormData, [name]: value };
        });
    };

    const handleChangeUserGroup = value => {
        if (value === USER_GROUP.BOTH) {
            setFormData({
                ...formData,
                allEmployeesInPerk: true,
                allClientsInPerk: true,
            });
        } else if (value === USER_GROUP.EMPLOYEES) {
            setFormData({
                ...formData,
                allEmployeesInPerk: true,
                allClientsInPerk: false
            });
        } else if (value === USER_GROUP.CLIENTS) {
            setFormData({
                ...formData,
                allEmployeesInPerk: false,
                allClientsInPerk: true
            });
        }
    };

    const handleEditPerk = perk => {
        setUpdatingUuid(perk.uuid);
        setIsFormVisible(true);
        setFormData({
            imageUrl: perk.image.imageUrl,
            type: perk.type,
            allEmployeesInPerk: perk.allEmployeesInPerk,
            allClientsInPerk: perk.allClientsInPerk,
            selectedCompanyRecipients: perk.selectedCompanyRecipients,
            startDate: perk.startDate,
            endDate: perk.endDate,
            companyName: perk.companyName,
            title: perk.title,
            description: perk.description,
            website: perk.website,
            promoCode: perk.promoCode,
            promoCodeImageUrl: perk.promoCodeImageUrl
        });
    };

    const handleCreateOrUpdate = () => {
        console.log(formData);
    };

    return (
        <PageContainer>
            { !isFormVisible && (
                <TablePage
                    perks={perks}
                    isFetching={isFetching}
                    allPerksChecked={allPerksChecked}
                    handleCheckAllPerks={handleCheckAllPerks}
                    checkedPerks={checkedPerks}
                    handleCheckPerk={handleCheckPerk}
                    handleFormVisible={handleFormVisible}
                    handleEditPerk={handleEditPerk}
                />
            )}

            { isFormVisible && (
                <FormPage
                    handleFormVisible={handleFormVisible}
                    handleChangePerkType={handleChangePerkType}
                    formData={formData}
                    handleInputChange={handleInputChange}
                    handleCreateOrUpdate={handleCreateOrUpdate}
                    updatingUuid={updatingUuid}
                    handleChangeUserGroup={handleChangeUserGroup}
                />
            )}
        </PageContainer>
    );
};

export default withRouter(Perk);