import { useState, useEffect } from "react";
import axios from "axios";
import { Toast } from "src/components";
import { API_URL } from "src/scenes/App";

const usePerks = (token) => {
    const [perks, setPerks] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        const fetchPerks = async () => {
            setIsFetching(true);
            try {
                const response = await axios.get(`${API_URL}/perks`, {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                });
                setPerks(response.data.perks);
            } catch (error) {
                Toast.error("Unable to fetch perks. Please try again later or contact support if the issue persists.");
            } finally {
                setIsFetching(false);
            }
        };

        fetchPerks();
    }, [token]);

    return { perks, setPerks, isFetching };
};

export default usePerks;