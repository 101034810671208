import React, { useState } from "react";
import { FormField, Button, ExplainParagraphLabel, FlexContainer, PageHeadingLabel } from "src/components";
import { Grid, Divider } from "@material-ui/core";
import styled, { css } from "styled-components";
import CheckIcon from "src/img/check@2x.png";
import DropdownIcon from "src/img/new/chevron-down.png";
import AddIcon from "src/img/new/add.svg";
import { PERK_TYPE, PROMO_CODE_TYPE, USER_GROUP } from "src/constants/perks";

const ColumnHeader = styled.h1`
    color: #612684;
    font-weight: 700;
    font-size: 1.563rem;
    margin: 0 0 1.25rem;
`;

const PerkTypeOption = styled.button`
    position: relative;
    width: 9.6rem;
    height: 2.9rem;
    border: 1px solid #e9ebef;
    border-radius: 12px;
    text-align: center;
    font-size: 0.875rem;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 700;
    background-color: #fff;
    cursor: pointer;

    ${(props) =>
        props.active &&
        css`
            background-color: #000;
            color: #fff;
            font-weight: 500;`}
`;

const FormButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem 3rem;
    gap: 1.25rem;
`;

const InputLabel = styled.label`
    font-family: Roboto, Hevetica, sans-serif;
    font-weight: 700;
    font-size: 0.938rem;
    color: #000;
`;

const InputDescription = styled.p`
    font-family: Roboto, Hevetica, sans-serif;
    font-weight: ${p => p.fontWeight || "normal"};
    font-size: ${p => p.size || "0.813rem"};
    color: ${p => p.color || "#8291b2"};
    margin: ${p => p.margin || "7px 0 10px"};
    text-align: ${p => p.align || "left"};
`;

const TextInput = styled(FormField)`
    font-family: Roboto, Helvetica, sans-serif;
    height: 3.125rem;
    border: 1px solid #e9ebef;
    border-radius: 12px;
    text-align: left;
    font-weight: 400;
    font-size: 0.938rem;
    color: #000;
    margin: 0;

    ::placeholder {
        text-align: left;
        color: #808080;
        font-family: Roboto, Helvetica, sans-serif;
        font-size: 0.938rem;
    }
`;

const TextArea = styled.textarea`
    font-family: Roboto, Helvetica, sans-serif;
    color: #000;
    font-size: 0.938rem;
    font-weight: 400;
    border: 1px solid #e9ebef;
    border-radius: 12px;
    padding: 10px 20px;
    resize: none;
    height: 8.5rem;
    width: 90%;

    ::placeholder {
        text-align: left;
        color: #808080;
        font-family: Roboto, Helvetica, sans-serif;
        font-size: 0.938rem;
    }

    &:focus {
        border: 1px solid #000;
        outline: none;
    }
`;

const ImageContainer = styled.div`
    height: 14.688rem;
    width: 100%;
    border: 1px solid #e9ebef;
    border-radius: 15px;
    margin: ${p => p.margin || "0"};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const PromoCodeType = styled.select`
    height: 3.125rem;
    width: 100%;
    padding: 0.3rem 1.1rem;
    border: 1px solid #e9ebef;
    border-radius: 12px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 0.938rem;
    color: #000;
    background-image: url(${DropdownIcon});
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
    appearance: none;

    &:focus {
        border: 1px solid #000;
        outline: none;
    }
`;

const FormPage = ({
    handleFormVisible,
    handleChangePerkType,
    formData,
    handleInputChange,
    handleCreateOrUpdate,
    updatingUuid,
    handleChangeUserGroup
}) => {
    const [promoCodeType, setPromoCodeType] = useState(PROMO_CODE_TYPE.TEXT);

    const isGeneral = (type) => type === PERK_TYPE.GENERAL;

    const isGiftPerk = (type) => type === PERK_TYPE.GIFT;

    const GreenCheck = ({ active }) => active && <img src={CheckIcon} style={{ height: "24px", position: "absolute", top: "10px", right: "6px" }} />;

    const perkIsForEmployees = (formData) => formData.allEmployeesInPerk && !formData.allClientsInPerk;

    const perkIsForClients = (formData) => !formData.allEmployeesInPerk && formData.allClientsInPerk;

    const perkIsForEmployeesAndClients = (formData) => formData.allEmployeesInPerk && formData.allClientsInPerk;

    const handlePromoCodeChange = e => {
        setPromoCodeType(e.target.value);
    };

    return (
        <>
            <PageHeadingLabel>
                { updatingUuid ? "Edit Perk" : "Create New Perk" }
            </PageHeadingLabel>

            <ExplainParagraphLabel size="0.875rem" width="100%" maxWidth="44rem">
                Design and assign exclusive perks for all employees and clients, or target specific groups. You can also create <br />
                gift perks for individual users. Customize your perks to reward and engage your team with unique benefits.
            </ExplainParagraphLabel>

            <FlexContainer
                color="#fff"
                style={{
                    maxWidth: "84.688rem",
                    height: "76.25rem",
                    border: "1px solid #8291b2",
                    borderRadius: "7px",
                    marginTop: "1.4rem"
                }}
            >
                <Grid container style={{ padding: "2.5rem 3rem 3.5rem" }}>
                    <Grid item md={6}>
                        <ColumnHeader>
                            Perk Information
                        </ColumnHeader>

                        <Grid container direction="column" spacing={2}>
                            <Grid item md={9}>
                                <InputLabel>
                                    Select Perk Type
                                </InputLabel>
                                <InputDescription>
                                    Select wether this perk will be available to most users or gifted to specific users.
                                </InputDescription>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                                    <Grid item>
                                        <PerkTypeOption active={isGeneral(formData.type)} onClick={() => handleChangePerkType(PERK_TYPE.GENERAL)}>
                                            General Perk
                                            <GreenCheck active={isGeneral(formData.type)} />
                                        </PerkTypeOption>
                                    </Grid>
                                    <Grid item>
                                        <PerkTypeOption active={isGiftPerk(formData.type)} onClick={() => handleChangePerkType(PERK_TYPE.GIFT)}>
                                            Gift Perk
                                            <GreenCheck active={isGiftPerk(formData.type)} />
                                        </PerkTypeOption>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {formData.type === PERK_TYPE.GENERAL && (
                                <Grid item md={10}>
                                    <InputLabel>
                                        Select Perk User Group
                                    </InputLabel>
                                    <InputDescription>
                                        Select to display this perk to employees, clients or both user groups.
                                    </InputDescription>
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <PerkTypeOption active={perkIsForEmployees(formData)} onClick={() => handleChangeUserGroup(USER_GROUP.EMPLOYEES)}>
                                                All Employees
                                                <GreenCheck active={perkIsForEmployees(formData)} />
                                            </PerkTypeOption>
                                        </Grid>
                                        <Grid item>
                                            <PerkTypeOption active={perkIsForClients(formData)} onClick={() => handleChangeUserGroup(USER_GROUP.CLIENTS)}>
                                                All Clients
                                                <GreenCheck active={perkIsForClients(formData)} />
                                            </PerkTypeOption>
                                        </Grid>
                                        <Grid item>
                                            <PerkTypeOption active={perkIsForEmployeesAndClients(formData)} onClick={() => handleChangeUserGroup(USER_GROUP.BOTH)}>
                                                Both
                                                <GreenCheck active={perkIsForEmployeesAndClients(formData)} />
                                            </PerkTypeOption>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}

                            {formData.type === PERK_TYPE.GIFT && (
                                <Grid item md={9}>
                                    <InputLabel>
                                        Select Gift Perk Users
                                    </InputLabel>
                                    <InputDescription>
                                        Select the users you with to gift this perk to.
                                    </InputDescription>
                                    <Button
                                        backgroundColor="#000"
                                        color="#fff"
                                        width="14.5rem"
                                        height="2.688rem"
                                        padding="0"
                                        style={{
                                            borderRadius: "12px",
                                            marginBottom: "3.5px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            position: "relative"
                                        }}
                                    >
                                        <p style={{ marginRight: "1.688rem" }}>Add People</p>
                                        <img src={AddIcon} style={{ height: "2.25rem", position: "absolute", right: "59px", top: "6px" }} />
                                    </Button>
                                </Grid>
                            )}

                            <Grid item md={9}>
                                <InputLabel>
                                    Select Perk Validation Dates
                                </InputLabel>
                                <InputDescription>
                                    Choose the start and end dates to define how long this perk will be available.
                                </InputDescription>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                                    <Grid item md={6}>
                                        <TextInput
                                            name="startDate"
                                            placeholder="Select start date"
                                            type="date"
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <TextInput
                                            name="endDate"
                                            placeholder="Select end date"
                                            type="date"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item md={9}>
                                <InputLabel>
                                    Company Name Of Perk
                                </InputLabel>
                                <InputDescription>
                                    Enter the name of the retailer or provider offering this perk.
                                </InputDescription>
                                <TextInput
                                    name="companyName"
                                    value={formData.companyName ?? ""}
                                    placeholder="e.g., “Ray-Ban”"
                                    onChange={handleInputChange}
                                />
                            </Grid>

                            <Grid item md={9}>
                                <InputLabel>
                                    Perk Heading
                                </InputLabel>
                                <InputDescription>
                                    Enter a clear and engaging title for your perk.
                                </InputDescription>
                                <TextInput
                                    name="title"
                                    value={formData.title ?? ""}
                                    placeholder="e.g., “20% Off Your Next Purchase”"
                                    onChange={handleInputChange}
                                />
                            </Grid>

                            <Grid item md={9}>
                                <InputLabel>
                                    Perk Description
                                </InputLabel>
                                <InputDescription>
                                    Provide a brief description of the perk, outlining the benefits and details for users.
                                </InputDescription>
                                <TextArea
                                    name="description"
                                    value={formData.description ?? ""}
                                    placeholder="e.g., “Enjoy exclusive savings on all products this month”"
                                    onChange={handleInputChange}
                                />
                            </Grid>

                            <Grid item md={9}>
                                <InputLabel>
                                    Perk Company Website
                                </InputLabel>
                                <InputDescription>
                                    Enter the website URL for the company offering the perk.
                                </InputDescription>
                                <TextInput
                                    name="website"
                                    value={formData.website ?? ""}
                                    placeholder="e.g., www.companyname.com"
                                    onChange={handleInputChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            <Grid item md={8}>
                                <ColumnHeader>
                                    Upload Perk Image
                                </ColumnHeader>

                                <InputLabel>
                                    Upload Image
                                </InputLabel>
                                <InputDescription color="#612684" fontWeight="500">
                                    Upload the company’s logo or an image representing the perk.
                                </InputDescription>
                                <ImageContainer>
                                    <Button
                                        backgroundColor="#000"
                                        color="#fff"
                                        border="1px solid #000"
                                        size="0.813rem"
                                        style={{ borderRadius: "7px", boxShadow: "none" }}
                                    >
                                        Browse File
                                    </Button>
                                    <InputDescription color="#808080" size="0.813rem" margin="15px 0 0" align="center">
                                        Supported formats: JPG, PNG. Max size: 5MB. <br />
                                        Aspect Ratio 16:9 - 1920px x 1080px
                                    </InputDescription>
                                </ImageContainer>
                            </Grid>

                            <Grid item md={8}>
                                <ColumnHeader>
                                    Perk Code
                                </ColumnHeader>

                                <InputLabel>
                                    Enter Text Code Or Upload Image Asset
                                </InputLabel>
                                <InputDescription color="#612684" fontWeight="500">
                                    Select to provide a promo/discount code, or upload an image asset <br />
                                    such as a QR code or barcode for this perk.
                                </InputDescription>
                                <PromoCodeType onChange={handlePromoCodeChange}>
                                    <option value={PROMO_CODE_TYPE.TEXT}>Promo/Discount Code</option>
                                    <option value={PROMO_CODE_TYPE.IMAGE}>Image (eg: QR code/Barcode)</option>
                                </PromoCodeType>

                                {promoCodeType === PROMO_CODE_TYPE.TEXT && (
                                    <>
                                        <InputDescription margin="13px 0 10px">
                                            Enter you perk promo/discount code here.
                                        </InputDescription>
                                        <TextInput
                                            name="promoCode"
                                            value={formData.promoCode ?? ""}
                                            placeholder="e.g., WELCOME10"
                                            onChange={handleInputChange}
                                        />
                                    </>
                                )}

                                {promoCodeType === PROMO_CODE_TYPE.IMAGE && (
                                    <ImageContainer margin="35px 0 0">
                                        <Button
                                            backgroundColor="#000"
                                            color="#fff"
                                            border="1px solid #000"
                                            size="0.813rem"
                                            style={{ borderRadius: "7px", boxShadow: "none" }}
                                        >
                                            Browse File
                                        </Button>
                                        <InputDescription color="#808080" size="0.813rem" margin="15px 0 0" align="center">
                                            Supported formats: JPG, PNG. Max size: 5MB. <br />
                                            Aspect Ratio 16:9 - 1920px x 1080px
                                        </InputDescription>
                                    </ImageContainer>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider />

                <FormButtonsContainer>
                    <Button
                        width="8.75rem"
                        height="2.375rem"
                        size="0.875rem"
                        backgroundColor="#fff"
                        color="#000"
                        border="1px solid #000"
                        style={{ borderRadius: "7px", boxShadow: "none" }}
                        onClick={handleFormVisible}
                    >
                        Cancel
                    </Button>
                    <Button
                        width="8.75rem"
                        height="2.375rem"
                        size="0.875rem"
                        backgroundColor="#006cff"
                        color="#fff"
                        border="1px solid #006cff"
                        style={{ borderRadius: "7px", boxShadow: "none" }}
                        onClick={handleCreateOrUpdate}
                    >
                        Save
                    </Button>
                </FormButtonsContainer>
            </FlexContainer>
        </>
    );
};

export default FormPage;