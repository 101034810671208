import React, { Component } from "react";
import { FlexContainer, LottieLoadingIndicator, Text } from "src/components";
import SurveyRatingCard from "./SurveyRatingCard";
import SurveySatisfactionCard from "./SurveySatisfactionCard";
import SurveyYesNoCard from "./SurveyYesNoCard";
import { SurveyFeedbackCard } from "./SurveyFeedbackCard";
import { EnumSurveyType } from "../Enums";


const surveyTypeIdentifier = (surveyType, survey, surveyQuestion, responsesStats, fetchingResponseStats, user) => {
    if (fetchingResponseStats) {
        return <LottieLoadingIndicator/>;
    }
    switch (surveyType) {
        case EnumSurveyType.YESNO:
            return <SurveyYesNoCard surveyQuestion={surveyQuestion} responsesStats={responsesStats} fetchingResponseStats={fetchingResponseStats}/>;
        case EnumSurveyType.FEEDBACK:
            return <SurveyFeedbackCard surveyQuestion={surveyQuestion} responsesStats={responsesStats} fetchingResponseStats={fetchingResponseStats} user={user} survey={survey} />;
        case EnumSurveyType.RATING:
            return <SurveyRatingCard surveyQuestion={surveyQuestion} responsesStats={responsesStats} fetchingResponseStats={fetchingResponseStats} />;
        case EnumSurveyType.SATISFACTION:
            return <SurveySatisfactionCard surveyQuestion={surveyQuestion} responsesStats={responsesStats} fetchingResponseStats={fetchingResponseStats} />;
        default:
            break;
    }
};

export class SurveyQuestions extends Component {
    render() {
        const { insights, surveyType, survey, responsesStats, fetchingResponseStats, user } = this.props;

        return (
            <FlexContainer align="center" style={{ width: "100%" }} >
                <Text color="#000" size="20px" weight="bold" align="left" margin="20px 0 10px 0">
                    Question 1
                </Text>
                <Text color="#000" weight="500" size="15px" align="left" margin="0 0 15px 0">
                    {insights.surveyQuestions[0].question}
                </Text>
                {surveyTypeIdentifier(surveyType, survey, insights.surveyQuestions[0], responsesStats, fetchingResponseStats, user)}
                {
                    insights.surveyQuestions[1] &&
                    <>
                        <Text color="#000" size="20px" weight="bold" align="left" margin="20px 0 10px 0">
                            Question 2
                        </Text>
                        <Text color="#000" weight="500" size="15px" align="left" margin="0 0 15px 0">
                            {insights.surveyQuestions[1].question}
                        </Text>
                        {surveyTypeIdentifier(surveyType, survey, insights.surveyQuestions[1], responsesStats, fetchingResponseStats, user)}
                    </>
                }
                {
                    insights.surveyQuestions[2] &&
                    <>
                        <Text color="#000" size="20px" weight="bold" align="left" margin="20px 0 10px 0">
                            Question 3
                        </Text>
                        <Text color="#000" weight="500" size="15px" align="left" margin="0 0 15px 0">
                            {insights.surveyQuestions[2].question}
                        </Text>
                        {surveyTypeIdentifier(surveyType, survey, insights.surveyQuestions[2], responsesStats, fetchingResponseStats, user)}
                    </>
                }
            </FlexContainer>
        );
    }
}