import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { LottieLoadingIndicator, Text } from "src/components";
import { countSurveyResponseValue, generateYesNoLineGraphData } from "../Utils";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const OverviewCard = styled.div`
    width: 860px;
    height: 160px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 5px #00000026;
    border: 1px solid #8291B2;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
    position: relative;
`;

const OverviewHalfColor = styled.div`
    width: 860px;
    height: 80px;
    /* UI Properties */
    background: #F0F0F0 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 5px #00000026;
    border-radius: 0 0 10px 10px;
    opacity: 1;
    position: absolute;
    bottom: 0;
`;

const OverviewCounterContainer = styled.div`
    width: 200px;
    height: 100%;
    margin: auto 0 4% 16px;
`;

const OverviewCounterItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: ${p => p.marginBottom}
`;

const OverviewCounterCircleIcon = styled.div`
    width: 47px;
    height: 47px;
    background: #000;
    border-radius: 25px;
`;

const OverviewCounterDetails = styled.div`
    display: flex;
    width: 110px;
    flex-direction: column;
    margin-left: 16px;
    text-align: left;
`;

const SurveyYesNoCard = (props) => {
    const { surveyQuestion, responsesStats, fetchingResponseStats } = props;
    const [yesCount, setYesCount] = useState(0);
    const [noCount, setNoCount] = useState(0);
    const [lineGraphData, setLineGraphData] = useState({ labels: [], dataSets: [] });
    const [startAtZero, setStartAtZero] = useState(false);

    /**
     * this is the options for the ChartJS,
     * startAtZero is changed to be dynamic per chart since there is
     * an open possibility that there's only 1 response being sent,
     * and the chart doesn't display data if its only 1 item
     */
    const options = {
        responsive: true,
        legend: {
            display: false,
        },
        plugins: {
            tooltip: {
                enabled: false
            },
            title: {
                display: false,
            },
            legend: { 
                display: false,
            }
        },
        interaction: {
            intersect: false,
        },
        maintainAspectRatio: false,
        scales: {
            beginAtZero: startAtZero,
            x: {
                display: false,
                title: {
                    display: false
                },
                gridLines: {
                    display: false
                },
                scaleLabel: {
                    display: false
                }
            },
            y: {
                display: false,
                title: {
                    display: false,
                },
                gridLines: {
                    display: false
                },
                scaleLabel: {
                    display: false
                },
                suggestedMin: -1,
                suggestedMax: 1
            }
        },
        elements: {
            point: {
                hoverRadius: 0
            }
        }
    };

    useEffect(() => {
        if (!fetchingResponseStats && responsesStats) {
            const yesCountedResult = countSurveyResponseValue(responsesStats, "yes", surveyQuestion.uuid);
            const noCountedResult = countSurveyResponseValue(responsesStats, "no", surveyQuestion.uuid);
            setYesCount(yesCountedResult);
            setNoCount(noCountedResult);
            generateYesNoLineGraphData(responsesStats, setLineGraphData, setStartAtZero);
        }
    }, [yesCount, noCount]);

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <OverviewCard>
                <div style={{ zIndex: 1, width: "100%", height: "100%", display: "flex", borderRadius: "12px" }}>
                    { lineGraphData?.labels?.length > 0 &&
                        <Line
                            options={options}
                            data={lineGraphData}
                            redraw={true}
                            responsive={true}
                            aspectRatio={2}
                            maintainAspectRatio={false}
                            width={"100%"}
                            style={{ borderRadius: "10px", margin: "auto 0", height: "99%" }}
                        />
                    }
                </div>
                <OverviewHalfColor />
            </OverviewCard>
            <OverviewCounterContainer>
                { fetchingResponseStats &&
                    <LottieLoadingIndicator/>
                }
                { !fetchingResponseStats &&
                    <>
                        <OverviewCounterItemContainer marginBottom="30px">
                            <OverviewCounterCircleIcon>
                                <Text margin="14px 0" color="#fff" >
                                    Yes
                                </Text>
                            </OverviewCounterCircleIcon>
                            <OverviewCounterDetails>
                                <Text margin="0" align="left" size="20px">
                                    { yesCount }
                                </Text>
                                <Text margin="0" align="left" size="16px">
                                    Answered &apos;Yes&apos;
                                </Text>
                            </OverviewCounterDetails>
                        </OverviewCounterItemContainer>
                        <OverviewCounterItemContainer>
                            <OverviewCounterCircleIcon>
                                <Text margin="14px 0" color="#fff" >
                                    No
                                </Text>
                            </OverviewCounterCircleIcon>
                            <OverviewCounterDetails>
                                <Text margin="0" align="left" size="20px">
                                    { noCount }
                                </Text>
                                <Text margin="0" align="left" size="16px">
                                    Answered &apos;No&apos;
                                </Text>
                            </OverviewCounterDetails>
                        </OverviewCounterItemContainer>
                    </>
                }
            </OverviewCounterContainer>
        </div>
    );
};

export default SurveyYesNoCard;