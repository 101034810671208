import React from "react";
import { Grid } from "@material-ui/core";
import { Button, FlexContainer, LottieAnimation } from "src/components";
import PurpleLottieJson from "src/scenes/Noticeboard/assets/purple-lottie.json";
import Checkbox from "src/scenes/EmployeeList/components/Checkbox";
import styled from "styled-components";
import SearchIcon from "src/img/new/search.svg";
import DropdownIcon from "src/img/new/drop-down.svg";
import moment from "moment";

const TableControls = styled.div`
    position: relative;
    width: 100%;
    max-width: 94.5rem;
    background: #fff;
    height: auto;
    min-height: 5.625rem;
    display: flex;
    align-items: center;
    border-radius: 7px 7px 0 0;
    border: 1px solid #8291B2;
    border-bottom: none;
    box-sizing: border-box;
`;

const SearchInput = styled.input`
    position:relative;
    background-image: url(${SearchIcon});
    background-repeat: no-repeat;
    text-indent: 25px;
    background-position: 16px center;
    line-height: 100%;
    width: 100%;
    border: 1px solid #000;
    max-width: 31.75rem;
    height: 2.438rem;
    margin-left: 1.4375rem;
    margin-right: 1.4375rem;
    border-radius: 10px;
    font-size: 0.875rem;
    text-align: left;
    padding: 0 0 0 1rem;
    box-sizing: border-box;
    ::placeholder {
        text-align: left;
        font-weight: 700;
        font-size: 0.875rem;
    }

    &:focus {
        outline: none;
    }
`;

const TableFilter = styled.select`
    height: 2.438rem;
    width: 11.125rem;
    border-radius: 7px;
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #000;
    padding: 0.3em 1.1em;
    color: #000;
    font-size: 0.875rem;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 700;
    background-image: url(${DropdownIcon});
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
    appearance: none;

    &:focus {
        outline: none;
    }
`;

const TableButton = styled(Button)`
    border-radius: 10px !important;
    border: ${p => p.border || "none"};
    height: 2.4375rem;
    width: ${p => p.width};
    color: #fff;
    background-color: ${p => p.backgroundColor};
    box-sizing: border-box;
    font-size: 0.75rem;
    font-weight: 500;
    margin-left: ${p => p.marginLeft};
    margin-right: ${p => p.marginRight};
    padding: 0 !important;
`;

const Th = styled.th`
    width: ${p => p.width};
    text-align: ${p => p.textAlign || "left"};
`;

const Td = styled.td`
    padding: 0 1em !important;
    height: 3.938rem;
    font-size: 0.75rem !important;
    white-space: normal !important;
    text-align: ${p => p.textAlign || "left"};
`;

const TdButton = styled.button`
    height: 2rem;
    background-color: ${p => p.backgroundColor};
    color: ${p => p.color};
    border: ${p => p.border};
    border-radius: 7px;
    width: 6.25rem;
    cursor: pointer;
    font-weight: 700;
    font-size: 0.75rem;
`;

const PerkImgContainer = styled.div`
    width: 83px;
    height: 47px;
    display: flex;
    justify-content: center;
    border: 1px solid #8291B2;
    border-radius: 7px;
`;

const PerkImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px;
    align-self: center;
`;

const PerksTable = ({
    perks,
    isFetching,
    allPerksChecked,
    handleCheckAllPerks,
    checkedPerks,
    handleCheckPerk,
    handleFormVisible,
    handleEditPerk
}) => {

    const trimText = text => {
        if (text && text.length > 80) {
            return text.slice(0, 80).trim() + "...";
        }
        return text ?? "";
    };

    const formatExpiryDate = date => {
        const currentDate = moment();
        const expirationDate = moment(date);
        if (currentDate.isAfter(expirationDate)) {
            return expirationDate.format("MMMM D, YYYY");
        }
        return expirationDate.format("MMMM D, YYYY");
    };

    const promoType = perk => {
        return perk.promoCode ? "Text" : "Image";
    };

    const renderExtraRows = () => {
        const minRows = 10;
        const noOfRowsToRender = minRows - perks.length;
        const extraRows = [];

        for (let i = 0; i < noOfRowsToRender; i++) {
            extraRows.push(
                <tr key={`empty-${i}`}>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                </tr>
            );
        }

        return extraRows;
    };

    return (
        <>
            { isFetching && (
                <FlexContainer
                    justifyContent="center"
                    alignItems="center"
                    color="#fff"
                    style={{
                        maxWidth: "94.5rem",
                        height: "44.188rem",
                        border: "1px solid #8291B2",
                        borderRadius: "7px"
                    }}
                >
                    <LoadingIndicator />
                </FlexContainer>
            )}

            { !isFetching && (
                <>
                    <TableControls>
                        <Grid item md={7}>
                            <SearchInput placeholder="Search Perk" type="text" />
                            <TableFilter>
                                <option value="active">Active</option>
                                <option value="expired">Expired</option>
                            </TableFilter>
                        </Grid>

                        <Grid item md={5}>
                            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                                {checkedPerks.length > 0 && (
                                    <TableButton
                                        backgroundColor="#ff7171"
                                        width="10.75rem"
                                    >
                                        Delete Selected Perks
                                    </TableButton>
                                )}
                                <TableButton
                                    backgroundColor="#006cff"
                                    width="13.125rem"
                                    marginLeft="1.4375rem"
                                    marginRight="1.4375rem"
                                    onClick={handleFormVisible}
                                >
                                    Create New Perk
                                </TableButton>
                            </Grid>
                        </Grid>
                    </TableControls>

                    <div className="scrollable-table" style={{ maxWidth: "94.5rem" }}>
                        <table>
                            <thead>
                                <tr>
                                    <Th width="4%" textAlign="center">
                                        <label>
                                            <Checkbox
                                                key="selectAll"
                                                readOnly={false}
                                                checked={allPerksChecked}
                                                onChange={handleCheckAllPerks}
                                            />
                                        </label>
                                    </Th>
                                    <Th width="9%">Image</Th>
                                    <Th width="16%">Company Name</Th>
                                    <Th width="25%">Heading</Th>
                                    <Th width="9%">Code</Th>
                                    <Th width="9%">Perk Expiry</Th>
                                    <Th width="10%">User Group</Th>
                                    <Th width="9%" textAlign="center">Insights</Th>
                                    <Th width="9%" textAlign="center">Edit Perk</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {perks.map(perk => (
                                    <tr key={perk.uuid}>
                                        <Td textAlign="center">
                                            <label>
                                                <Checkbox
                                                    readOnly={false}
                                                    checked={checkedPerks.includes(perk.uuid)}
                                                    onChange={(e) => handleCheckPerk(e, perk.uuid)}
                                                />
                                            </label>
                                        </Td>
                                        <Td>
                                            <PerkImgContainer>
                                                <PerkImg src={perk.image.imageUrl} />
                                            </PerkImgContainer>
                                        </Td>
                                        <Td>{perk.companyName}</Td>
                                        <Td>{trimText(perk.description)}</Td>
                                        <Td>{promoType(perk)}</Td>
                                        <Td>{formatExpiryDate(perk.endDate)}</Td>
                                        <Td>Employees & Clients</Td>
                                        <Td textAlign="center">
                                            <TdButton
                                                backgroundColor="#eaf3ff"
                                                color="#006cff"
                                                border="1px solid #006cff"
                                            >
                                                View
                                            </TdButton>
                                        </Td>
                                        <Td textAlign="center">
                                            <TdButton
                                                backgroundColor="#fff"
                                                color="#000"
                                                border="1px solid #000"
                                                onClick={() => handleEditPerk(perk)}
                                            >
                                                Edit
                                            </TdButton>
                                        </Td>
                                    </tr>
                                ))}

                                {renderExtraRows()}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </>
    );
};

const LoadingIndicator = () => {
    return (
        <FlexContainer>
            <LottieAnimation animationJsonData={PurpleLottieJson} />
        </FlexContainer>
    );
};

export default PerksTable;