import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Text, LottieLoadingIndicator } from "src/components";
import IconRating from "src/img/survey/updatedIcons/star@2x.png";
import RatingGauge from "src/img/survey/updatedIcons/Gauges/Gauge 2@2x.png";
import GaugeNeedle from "src/img/survey/updatedIcons/Gauges/needle@2x.png";
import { countSurveyResponseValue, evaluateAverageCategory } from "../Utils";
import { surveyRatingRotation } from "../Enums";

const OverviewCard = styled.div`
    width: 589px;
    height: 333px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 5px #00000026;
    border: 1px solid #8291B2;
    border-radius: 10px;
    opacity: 1;
`;

const RatingRow = styled.div`
    width: 100%;
    height: 100%;
    border-bottom: ${p => p.borderRowBottom || "1px solid #8291B2"};
    display: flex;
    flex-direction: row;
`;

const RatingIconCell = styled.div`
    display: flex;
    width: 70%;
    justify-content: start;
    margin-left: 20px;
`;

const RatingNumberCell = styled.div`
    display: flex;
    width: 30%;
    justify-content: end;
    margin-right: 20px;
    border-left: 1px solid #8291B2;
`;

const SurveyRatingCard = (props) => {
    const { surveyQuestion, responsesStats, fetchingResponseStats } = props;
    const [fiveStarsCount, setFiveStarsCount] = useState(0);
    const [fourStarsCount, setFourStarsCount] = useState(0);
    const [threeStarsCount, setThreeStarsCount] = useState(0);
    const [twoStarsCount, setTwoStarsCount] = useState(0);
    const [oneStarCount, setOneStarCount] = useState(0);
    const [averageRating, setAverageRating] = useState(0);
    const [surveyNeedleRotation, setSurveyNeedleRotation] = useState("");
    
    useEffect(() => {
        if (!fetchingResponseStats && responsesStats) {
            setFiveStarsCount(countSurveyResponseValue(responsesStats, "5", surveyQuestion.uuid));
            setFourStarsCount(countSurveyResponseValue(responsesStats, "4", surveyQuestion.uuid));
            setThreeStarsCount(countSurveyResponseValue(responsesStats, "3", surveyQuestion.uuid));
            setTwoStarsCount(countSurveyResponseValue(responsesStats, "2", surveyQuestion.uuid));
            setOneStarCount(countSurveyResponseValue(responsesStats, "1", surveyQuestion.uuid));

            let categoriesCountArray = [oneStarCount, twoStarsCount, threeStarsCount, fourStarsCount, fiveStarsCount];
            setAverageRating(evaluateAverageCategory(categoriesCountArray));
        }
    }, [fiveStarsCount, fourStarsCount, threeStarsCount, twoStarsCount, oneStarCount]);

    useEffect(() => {
        const clamped = Math.min(Math.max(averageRating, 1), 5); // Clamp within [1, 5]
        const angle = surveyRatingRotation.One + (surveyRatingRotation.Five - surveyRatingRotation.One) * (clamped - 1) / 4;
        setSurveyNeedleRotation(angle);
    }, [averageRating]);

    return (
        <>
            { fetchingResponseStats && 
                <LottieLoadingIndicator />
            }
            { !fetchingResponseStats &&
                <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
                    <OverviewCard>
                        <div style={{ margin: "50px 35px", textAlign: "center", position: "relative" }}>
                            <div style={{ position: "relative" }}>
                                <img src={RatingGauge} alt={RatingGauge} height="200px" />
                                <img src={GaugeNeedle} alt={GaugeNeedle} style={{ height: "50px", position: "absolute", bottom: "8px", left: "180px", transformOrigin: "75% 50%", rotate: `${surveyNeedleRotation}deg`, translate: surveyNeedleRotation == 90 ? "0% 5%" : "" }} />
                            </div>
                            <Text>
                                This guage represents the total average results of users answers for <b>Question {surveyQuestion.questionOrder}</b>.
                            </Text>
                        </div>
                    </OverviewCard>

                    <OverviewCard style={{ width: "265px", display: "flex", flexDirection: "column" }}>
                        <RatingRow>
                            <RatingIconCell>
                                <img src={IconRating} alt={IconRating} height="36px" width="36px" style={{ margin: "auto 10px auto 0" }} />
                                <Text color="#8291B2" size="15px" margin="auto 0">
                                    5 Stars
                                </Text>
                            </RatingIconCell>
                            <RatingNumberCell>
                                <Text size="20px" color="#8291B2" weight="700" style={{ margin: "auto 0" }}>
                                    { fiveStarsCount }
                                </Text>
                            </RatingNumberCell>
                        </RatingRow>
                        <RatingRow>
                            <RatingIconCell>
                                <img src={IconRating} alt={IconRating} height="36px" width="36px" style={{ margin: "auto 10px auto 0" }} />
                                <Text color="#8291B2" size="15px" margin="auto 0">
                                    4 Stars
                                </Text>
                            </RatingIconCell>
                            <RatingNumberCell>
                                <Text size="20px" color="#8291B2" weight="700" style={{ margin: "auto 0" }}>
                                    { fourStarsCount }
                                </Text>
                            </RatingNumberCell>
                        </RatingRow>
                        <RatingRow>
                            <RatingIconCell>
                                <img src={IconRating} alt={IconRating} height="36px" width="36px" style={{ margin: "auto 10px auto 0" }} />
                                <Text color="#8291B2" size="15px" margin="auto 0">
                                    3 Stars
                                </Text>
                            </RatingIconCell>
                            <RatingNumberCell>
                                <Text size="20px" color="#8291B2" weight="700" style={{ margin: "auto 0" }}>
                                    { threeStarsCount }
                                </Text>
                            </RatingNumberCell>
                        </RatingRow>
                        <RatingRow>
                            <RatingIconCell>
                                <img src={IconRating} alt={IconRating} height="36px" width="36px" style={{ margin: "auto 10px auto 0" }} />
                                <Text color="#8291B2" size="15px" margin="auto 0">
                                    2 Stars
                                </Text>
                            </RatingIconCell>
                            <RatingNumberCell>
                                <Text size="20px" color="#8291B2" weight="700" style={{ margin: "auto 0" }}>
                                    { twoStarsCount }
                                </Text>
                            </RatingNumberCell>
                        </RatingRow>
                        <RatingRow borderRowBottom="unset !important">
                            <RatingIconCell>
                                <img src={IconRating} alt={IconRating} height="36px" width="36px" style={{ margin: "auto 10px auto 0" }} />
                                <Text color="#8291B2" size="15px" margin="auto 0">
                                    1 Star
                                </Text>
                            </RatingIconCell>
                            <RatingNumberCell>
                                <Text size="20px" color="#8291B2" weight="700" style={{ margin: "auto 0" }}>
                                    { oneStarCount }
                                </Text>
                            </RatingNumberCell>
                        </RatingRow>
                    </OverviewCard>
                </div>
            }
        </>
    );
};
export default SurveyRatingCard;