import React, { Component } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import {
    Toast, FlexContainer, Text, PageContainer,
    LottieLoadingIndicator,
    PageHeadingLabel
} from "src/components";
import { SurveyInfo } from "./SurveyInsightsComponents/SurveyInfo";
import { SurveyQuestions } from "./SurveyInsightsComponents/SurveyQuestions";
import { FormBackground, StyledButton } from "./Utils";
import { SurveyOverview } from "./SurveyInsightsComponents/SurveyOverview";
import SurveyUserSubmissionsModal from "../components/SurveyUserSubmissions";

class SurveyInsightPage extends Component {
    state = {
        fetchingInsight: true,
        fetchingSurvey: true,
        fetchingResponseStats: true,
        isShowViewUserSubissions: false,
        survey: {
            name: "...",
            surveyType: "...",
            startDate: "",
            finishDate: "",
            userGroup: "..."
        },
        insights: {
            numberOfUsersInTargetGroup: "...",
            numberOfUsersPromptedForSurvey: "...",
            numberOfTotalSurveysSubmitted: "...",
            surveyQuestions: [{}],
        },
        responsesStats: {},
        message: "",
        inputErrors: [],
        setInputTypeAs: "text",
        loading: false,
    };

    componentDidMount() {
        this.safeToInit();
    }

    safeToInit = () => {
        this.populateInsights();
        this.populateSurvey();
        this.populateResponseStats();
    };

    populateSurvey = () => {
        const { surveyUuid } = this.props;
        let surveyResponse = {};
        let hasError = true;

        axios.get(`${API_URL}/survey/company/${surveyUuid}`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            surveyResponse = res.data.survey;
            hasError = false;
        }).finally(() => {
            if (!hasError) {
                this.setState(prevState => ({
                    survey: surveyResponse,
                    fetchingSurvey: false,
                }));
            } else {
                this.setState(state => ({
                    fetchingSurvey: false,
                }));
            }
        }).catch(error => {
            if (!error.response) 
                return Toast.error(error.message);

            return Toast.error(error.response.data.error);
        });
    };

    populateInsights = () => {
        const { surveyUuid } = this.props;
        let surveyInsight = {};

        let hasError = true;

        axios.get(`${API_URL}/survey/company/insights/${surveyUuid}`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            surveyInsight = res.data;
            hasError = false;
        }).finally(() => {
            if (!hasError) {
                this.setState(prevState => ({
                    insights: surveyInsight,
                    fetchingInsight: false,
                }));
            } else {
                this.setState(state => ({
                    fetchingInsight: false,
                }));
            }
        }).catch(error => {
            if (!error.response) 
                return Toast.error(error.message);

            return Toast.error(error.response.data.error);
        });
    };

    populateResponseStats = () => {
        const { surveyUuid } = this.props;
        let surveyResponsesStats = {};
        let hasError = true;

        axios.get(`${API_URL}/survey/company/responsesStats/${surveyUuid}`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            surveyResponsesStats = res.data;
            hasError = false;
        }).finally(() => {
            if (!hasError) {
                this.setState(prevState => ({
                    responsesStats: surveyResponsesStats,
                    fetchingResponseStats: false,
                }));
            } else {
                this.setState(state => ({
                    fetchingResponseStats: false,
                }));
            }
        }).catch(error => {
            if (!error.response) {
                return Toast.error(error.message);
            }
            return Toast.error(error.response.data.error);
        });
    };

    showSurveyUserSubmissionsModal = (surveyUuid) => {
        this.setState({
            isShowViewUserSubissions: true,
            surveyUuid: surveyUuid
        });
    };

    hideSurveyUserSubmissionsModal = () => {
        this.setState({
            isShowViewUserSubissions: false,
        });
    };

    render() {
        const { survey, insights, responsesStats, fetchingInsight, fetchingSurvey, fetchingResponseStats, isShowViewUserSubissions } = this.state;
        const { showInsights, user } = this.props;

        return (
            <PageContainer>
                <PageHeadingLabel color="#23262D" size="40px" weight="500" align="left" margin="1em 0 0 0">
                    Survey Insights
                </PageHeadingLabel>
                <div style={{ maxWidth: "94.5rem" }}>
                    <FlexContainer direction="row" justifyContent="space-between" marginBottom="27px">
                        <Text color="#808080" size="14px" align="justify" maxWidth="44.5rem">
                            Unlock a comprehensive overview of your survey results with our Survey Insights. Tailored for admin users, this feature provides a visual representation of survey data, helping you decipher trends, feedback, and patterns at a glance. Analyse, understand, and make informed decisions with precision, all within one intuitive interface.
                        </Text>
                        <StyledButton backgroundColor="#FFF" color="#000" border="1px solid #000" style={{ width: "150px", height: "40px", borderRadius: "7px", weight: "bold", marginBlockStart: "auto" }}
                            onClick={() => { showInsights(false); }} >
                            Close
                        </StyledButton>
                    </FlexContainer>
                </div>

                <FormBackground style={{ padding: "2.5rem", width: "100%", overflowX: "auto" }}>
                    { fetchingInsight && <LottieLoadingIndicator />}

                    { !fetchingInsight &&
                        <div style={{ display: "flex", width: "100%", flexDirection: "row", flexWrap: "nowrap", overflowX: "auto" }}>
                            <div style={{ width: "17rem", textAlign: "left" }}>
                                <SurveyOverview
                                    showInsights={showInsights}
                                    survey={survey}
                                    insights={insights}
                                    surveyUuid={survey.surveyUuid}
                                    showSurveyUserSubmissions={this.showSurveyUserSubmissionsModal}
                                />
                            </div>
                            <div style={{ borderLeft: "1px solid #EFEFEF", marginLeft: "1.5rem", paddingLeft: "1.5rem", marginBottom: "2rem" }}>
                                <SurveyInfo showInsights={showInsights} survey={survey} fetchingSurvey={fetchingSurvey} />
                                <SurveyQuestions
                                    survey={survey}
                                    insights={insights}
                                    surveyType={survey.surveyType}
                                    responsesStats={responsesStats}
                                    fetchingResponseStats={fetchingResponseStats}
                                    user={user}
                                />
                            </div>
                        </div>
                    }
                </FormBackground>
                
                { isShowViewUserSubissions &&
                    <SurveyUserSubmissionsModal 
                        showDialog={isShowViewUserSubissions}
                        handleClose={this.hideSurveyUserSubmissionsModal}
                        insights={insights}
                        survey={survey}
                    />
                }

            </PageContainer>
        );
    }
}

export default SurveyInsightPage;