import React, { Component } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import {
    LoadingIndicator, FlexContainer, FormField, ErrorText,
    Text, Form, Button, ConfirmModal, Toast
} from "src/components";

import styled from "styled-components";

import { GridContainer, GridChild, CancelButton, NextStepButton, EditQuestionContainer, InputSelector } from "../components/Utils";
import { ErrorStrings, ErrorList, EnumSurveyShownIn, EnumSurveyShowFrequency } from "../components/Enums";

const RemoveButton = styled(Button)`
    height: 2.4rem;
    color: #FFFFFF;
    margin: 5px 5px;
`;

const Hr = styled.hr`
    margin: 25px;
    width:100%;
`;

class EditForm extends Component {
    state = {
        fetching: 0,
        survey: {
            name: "",
            surveyType: "",
            startDate: "",
            finishDate: "",
            userGroup: "",
            surveyQuestions: {
                0: { uuid: "", question: "" },
                1: {},
                2: {}
            },
            showIn: "",
            showFrequency: ""
        },
        message: "",
        inputErrors: [],
        setInputTypeAs: "text",
        loading: false
    };
    
    componentDidMount() {
        //this.safeToInit();
        //this.populateSurvey();

        this.setState(state => ({
            fetching: state.fetching + 1
        }), () => this.safeToInit());
    }

    safeToInit = () => {
        this.populateSurvey();

        this.setState(state => ({
            fetching: state.fetching - 1
        }));
    };

    populateSurvey = () => {
        const { surveyUuid } = this.props;

        this.setState(state => ({
            fetching: state.fetching + 1
        }));

        axios.get(`${API_URL}/survey/company/${surveyUuid}`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            const surveyResponse = res.data.survey;
            this.setState(prevState => ({
                survey: surveyResponse,
            }));
        }).finally(() => {
            this.setState(state => ({
                fetching: state.fetching - 1,
            }));
        }).catch(error => {
            if (error.response) {
                return Toast.error(error.response.data.error);
            } else {
                return Toast.error(error.message);
            }
        });
    };

    formSubmission = () => {
        this.setState({
            loading: true,
            inputErrors: [],
        });

        const { surveyUuid } = this.props;
        const surveyData = this.state.survey;
        const surveyQuestions = Object.keys(surveyData.surveyQuestions);

        let surveyQuestionItems = {};
        let questionOrder = 1;
        surveyQuestions.forEach(questionKey => {
            if (surveyData.surveyQuestions[questionKey].question !== "" && typeof surveyData.surveyQuestions[questionKey].question !== "undefined")
            {
                surveyData.surveyQuestions[questionKey].questionOrder = questionOrder;
                surveyQuestionItems[questionKey] = surveyData.surveyQuestions[questionKey];
                questionOrder++;
            }
        });

        const formatter = Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" });
        const formattedStartDate = surveyData.startDate ? formatter.format(new Date(surveyData.startDate)) : "";
        const formattedFinishDate = surveyData.finishDate ? formatter.format(new Date(surveyData.finishDate)) : "";

        let errors = this.getErrors();
        
        if (errors.length > 0) {
            this.setState({
                loading: false,
                inputErrors: errors,
            });

            return Toast.error("Please make sure your entries are correct");
        }

        axios.put(`${API_URL}/survey/company/${surveyUuid}`, {
            name: surveyData.name,
            surveyType: surveyData.surveyType,
            startDate: surveyData.startDate ? formattedStartDate + " 00:00:00" : null,
            finishDate: surveyData.finishDate ? formattedFinishDate + " 00:00:00" : null,
            userGroup: surveyData.userGroup,
            surveyQuestions: surveyQuestionItems,
            showIn: surveyData.showIn,
            showFrequency: surveyData.showFrequency
        },
        {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            const surveyResponse = res.data.survey;
            this.setState(prevState => ({
                survey: surveyResponse,
            }));

            return Toast.info("Edit Survey successful!");
        }).catch(error => {
            if (!error.response)
                return Toast.error(error.message);

            let errors = [];
            let dataErrorFields = error.response.data.error.fields;
            
            dataErrorFields.forEach(function(fieldKey) {
                switch (fieldKey) {
                case "startDateInvalid":
                    errors.push(ErrorList.DATE_START_DATE_INVALID);
                    return;
   
                case "finishDateInvalid":
                    errors.push(ErrorList.DATE_FINISH_DATE_INVALID);
                    return;

                case "startDateGreaterThanFinishDate":
                    errors.push(ErrorList.DATE_RANGE_INVALID);
                    return;

                case "surveyQuestionEmpty":
                    errors.push(ErrorList.EMPTY_QUESTION_ONE);
                    return;
                        
                default:
                    //NO ERROR TO CATCH
                    return;
                }
            });

            this.setState({
                inputErrors: errors,
            });

            return Toast.error("Please make sure your entries are correct");
        }).finally(error => {
            this.setState({
                loading: false,
            });
        });
    };

    confirmDelete = (surveyUuid) => {
        return ConfirmModal.open(
            "Permanently Remove Survey",
            <div>
                <p>Are you sure you want to remove this survey? All data from survey 
                    insights for this survey will be lost</p><br></br>
                <hr/>
            </div>,
            () => {this.deleteSurvey(surveyUuid); },
            "Remove",
            false
        );
    };

    deleteSurvey = (surveyUuid) => {
        const { hideEditForm, getSurveys } = this.props;

        ConfirmModal.close();

        this.setState(state => ({
            fetching: state.fetching + 1
        }));

        axios.delete(`${API_URL}/survey/company/${surveyUuid}`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            hideEditForm();
            Toast.info("Delete successful");
            getSurveys();
        }).catch(error => {
            if (error.response) {
                return Toast.error(error.response.data.error);
            } else {
                return Toast.error(error.message);
            }
        });
    };

    getErrors() {
        let survey = this.state.survey;
        let errors = [];

        if (survey.name === "") {
            errors.push(ErrorList.EMPTY_SURVEY_NAME);
        }
    
        if (survey.startDate === "") {
            errors.push(ErrorList.EMPTY_START_DATE);
        }

        if (survey.finishDate === "") {
            errors.push(ErrorList.EMPTY_FINISH_DATE);
        }

        if (survey.surveyQuestions[0] === undefined || (survey.surveyQuestions[0] !== undefined && survey.surveyQuestions[0].question === "")) {
            errors.push(ErrorList.EMPTY_QUESTION_ONE);
        }

        return errors;
    }

    inputSurveyName = (event) => {
        const surveyName = event.target.value;
        
        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                name: surveyName // update the value of specific key
            }
        }));
    };

    inputStartDate = (event) => {
        const startDate = event.target.value;

        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                startDate: startDate // update the value of specific key
            }
        }));
    };

    inputFinishDate = (event) => {
        const finishDate = event.target.value;

        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                finishDate: finishDate // update the value of specific key
            }
        }));
    };

    inputQuestion = (questionNumber, event) => {
        const questionString = event.target.value;

        this.setState(prevState => ({
            survey: {                  
                ...prevState.survey,   
                surveyQuestions: { 
                    ...prevState.survey.surveyQuestions,
                    [questionNumber]: {
                        ...prevState.survey.surveyQuestions[questionNumber],
                        question: questionString
                    }
                }        
            }
        }));
    }; 

    selectShowIn = (event) => {
        const showIn = event.target.value;

        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                showIn: showIn // update the value of specific key
            }
        }));
    };

    selectShowFrequency = (event) => {
        const showFrequency = event.target.value;

        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                showFrequency: showFrequency // update the value of specific key
            }
        }));
    };

    render() {
        const { hideEditForm, surveyUuid } = this.props;
        const { fetching, inputErrors, survey, loading, setInputTypeAs } = this.state;

        if (fetching > 0) {
            return (
                <>
                    <Text color="#23262D" size="40px" weight="500">
                        Edit Survey
                    </Text>
                    <LoadingIndicator />
                </>
            );
        }
        
        return (
            <>
                <Text color="#23262D" size="40px" weight="500">
                Edit Survey
                </Text>

                <FlexContainer justifyContent="center" alignItems="center" style={{ margin: "auto", minWidth: "600px" }}>
                    <Form width="100%" onSubmit={(e) => {e.preventDefault();}} >

                        <PageSurveyName survey={survey} inputSurveyName={this.inputSurveyName} inputErrors={inputErrors} />
                        <br/>

                        <Text color="#51315D" align="left" size="16px" weight="bolder">
                                Survey Start/Finish Date:
                        </Text>
                            
                        <PageSurveyDuration survey={survey} inputStartDate={this.inputStartDate} inputFinishDate={this.inputFinishDate} inputErrors={inputErrors} setInputTypeAs={setInputTypeAs}/> 
                       
                        <Text color="#51315D" align="left" size="16px" weight="bolder">
                                Survey Questions:
                        </Text>

                        <PageSurveyQuestions survey={survey} inputQuestion={this.inputQuestion} inputErrors={inputErrors} />

                        <PageSurveyFrequency survey={survey} inputErrors={inputErrors} selectShowIn={this.selectShowIn} selectShowFrequency={this.selectShowFrequency} /> 

                    </Form>

                    <Hr/>

                    <FlexContainer direction="row" className="tableHeaderContainer" width="100%">
                        
                        {
                            loading 
                                ?
                                ""
                                :
                                <RemoveButton backgroundColor="#ff6464" onClick={(e) => { this.confirmDelete(surveyUuid); } }>
                                    <span>Permanently Remove Survey </span>
                                </RemoveButton>
                        }

                        {
                            loading ? <LoadingIndicator height="20px" width="20px"/> :
                                <>
                                    <CancelButton style={{ marginLeft: "auto" }} onClick={(e) => {hideEditForm();}}>Back</CancelButton>
                                    <NextStepButton onClick={(e) => {this.formSubmission();}}>Save</NextStepButton>
                                </>
                        }     
                
                    </FlexContainer>
                </FlexContainer>
            </>
        );
    }
}

class PageSurveyName extends Component {
    render() {
        const { survey, inputSurveyName, inputErrors } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const surveyNameError = getErrorStrings(ErrorList.EMPTY_SURVEY_NAME);

        return (
            <>
                <Text color="#51315D" align="left" size="16px" weight="bolder">
                    Survey Name:
                </Text>
                <FormField borderRadius="26px" size="14px" padding="10px 20px" height="45px"
                    placeholderColor="#000000" border="1px solid #2D3037"
                    align="left"
                    width="100%"
                    type="text"
                    placeholder="Enter your survey name here"
                    onChange={(event) => {inputSurveyName(event);} }
                    errors={surveyNameError}
                    maxLength="100"
                    value={survey.name}
                >
                </FormField>
            </>
        );
    }
}

class PageSurveyDuration extends Component {
    state = {
        startDateSetInputTypeAs: "text",
        finishDateSetInputTypeAs: "text",
        tooltipActive: false
    };

    startDateChangeInputTypeToDate = (event) => {
        this.setState({
            startDateSetInputTypeAs: "date" 
        });
    };

    finishDateChangeInputTypeToDate = (event) => {
        this.setState({
            finishDateSetInputTypeAs: "date" 
        });
    };

    render() {
        const { survey, inputStartDate, inputFinishDate, inputErrors } = this.props;
        const { startDateSetInputTypeAs, finishDateSetInputTypeAs } = this.state;

        const formatter = Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" });
        const formattedStartDate = survey.startDate ? formatter.format(new Date(survey.startDate)) : "";
        const formattedFinishDate = survey.finishDate ? formatter.format(new Date(survey.finishDate)) : "";

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);

        const surveyStartDateError = getErrorStrings(ErrorList.EMPTY_START_DATE);
        const surveyFinishDateError = getErrorStrings(ErrorList.EMPTY_FINISH_DATE);
        
        const startDateInvalid = getErrorStrings(ErrorList.DATE_START_DATE_INVALID);
        const finishDateInvalid = getErrorStrings(ErrorList.DATE_FINISH_DATE_INVALID);

        const dateStartFormatInvalid = getErrorStrings(ErrorList.DATE_START_FORMAT_INVALID);
        const dateFinishFormatInvalid = getErrorStrings(ErrorList.DATE_FINISH_FORMAT_INVALID);

        const dateRangeInvalid = getErrorStrings(ErrorList.DATE_RANGE_INVALID);

        return (
            <>

                <GridContainer align="center" style={{ gridTemplateColumns: "50% 50%" }} >                    
                    <GridChild style={{ textAlign: "center", marginLeft: "0px" }}>
                        <Text color="#000" align="left" padding="0px 20px" size="16px" weight="bolder" margin="0">
                        Start
                        </Text>
                        <FormField borderRadius="26px" size="14px" padding="10px 20px" height="45px"
                            placeholderColor="#000000" border="1px solid #2D3037" 
                            align="left"
                            type={Object.keys(survey.startDate).length > 0 ? "date" : startDateSetInputTypeAs }
                            placeholder="Choose date..."
                            onChange={(event) => {inputStartDate(event);} }
                            onFocus={(event) => {this.startDateChangeInputTypeToDate(event); } }
                            errors={surveyStartDateError}
                            value={formattedStartDate}
                            max="9999-12-31"
                            disabled={survey.status === "Active"}
                            backgroundColor={survey.status === "Active" ? "#E2E2E2" : "white"}
                        >
                        </FormField>
                    </GridChild>

                    <GridChild style={{ textAlign: "center", marginRight: "0px" }}>
                        <Text color="#000" align="left" padding="0px 20px" size="16px" weight="bolder" margin="0">
                        Finish
                        </Text>
                        <FormField borderRadius="26px" size="14px" padding="10px 20px" height="45px"
                            placeholderColor="#000000" border="1px solid #2D3037"
                            align="left"
                            width="100%"
                            type={Object.keys(survey.finishDate).length > 0 ? "date" : finishDateSetInputTypeAs}
                            placeholder="Choose date..."
                            onChange={(event) => {inputFinishDate(event);} }
                            onFocus={(event) => {this.finishDateChangeInputTypeToDate(event);} }
                            errors={surveyFinishDateError}
                            value={formattedFinishDate}
                            max="9999-12-31"
                        >
                        </FormField>
                    </GridChild>
                </GridContainer>

                <ErrorText>
                    { startDateInvalid.length > 0 ? startDateInvalid : null }
                    { finishDateInvalid.length > 0 ? finishDateInvalid : null }
                    { dateStartFormatInvalid.length > 0 ? dateStartFormatInvalid : null }
                    { dateFinishFormatInvalid.length > 0 ? dateFinishFormatInvalid : null }
                    { dateRangeInvalid.length > 0 ? dateRangeInvalid : null }
                
                </ErrorText>
            </>
        );
    }
}

class PageSurveyQuestions extends Component {
    render() {
        const { inputQuestion, inputErrors, survey } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);   

        let surveyQuestionOneError = getErrorStrings(ErrorList.EMPTY_QUESTION_ONE);
        let surveyQuestionTwoError = getErrorStrings(ErrorList.EMPTY_QUESTION_TWO);
        let surveyQuestionThreeError = getErrorStrings(ErrorList.EMPTY_QUESTION_THREE);

        let surveyQuestionOne = (survey.surveyQuestions[0]) ? survey.surveyQuestions[0].question : "";
        let surveyQuestionTwo = (survey.surveyQuestions[1]) ? survey.surveyQuestions[1].question : "";
        let surveyQuestionThree = (survey.surveyQuestions[2]) ? survey.surveyQuestions[2].question : "";
        return (
            <>
                {<EditQuestionContainer questionNumber={1} questionValue={surveyQuestionOne} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionOneError} />}
           
                {<EditQuestionContainer questionNumber={2} questionValue={surveyQuestionTwo} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionTwoError} />}
          
                {<EditQuestionContainer questionNumber={3} questionValue={surveyQuestionThree} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionThreeError} />}
            </>
        );
    }
}

class PageSurveyFrequency extends Component {
    render() {
        const { survey, inputErrors, selectShowIn, selectShowFrequency } = this.props;
        
        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const showInError = getErrorStrings(ErrorList.EMPTY_SHOW_IN);
        const showFrequencyError = getErrorStrings(ErrorList.EMPTY_SHOW_FREQUENCY);

        return (

            <>
                <FlexContainer>
                    <Text color="#51315D" align="left" margin="10px 0px" size="16px" weight="500">
                    Show Survey in:
                    </Text>

                    <InputSelector fontWeight="normal" size="14px" color="#51315D" onChange={(event) => {selectShowIn(event);} } value={survey.showIn} >
                        <option value={EnumSurveyShownIn.QUOTE_SCREEN}>{EnumSurveyShownIn.QUOTE_SCREEN} (Main Screen)</option>
                        <option value={EnumSurveyShownIn.NOTICEBOARD_SCREEN}>{EnumSurveyShownIn.NOTICEBOARD_SCREEN}</option>
                        <option value={EnumSurveyShownIn.GOAL_TRACK_SCREEN}>{EnumSurveyShownIn.GOAL_TRACK_SCREEN}</option>
                        <option value={EnumSurveyShownIn.ARTICLES_SCREEN}>{EnumSurveyShownIn.ARTICLES_SCREEN}</option>
                        <option value={EnumSurveyShownIn.PLAYLISTS_SCREEN}>{EnumSurveyShownIn.PLAYLISTS_SCREEN}</option>
                        <option value={EnumSurveyShownIn.APP_SETTINGS_SCREEN}>{EnumSurveyShownIn.APP_SETTINGS_SCREEN}</option>
                    </InputSelector>

                    <ErrorText>
                        {showInError}
                    </ErrorText>

                    <Text color="#51315D" align="left" margin="10px 0px" size="16px" weight="500">
                    Select Survey Frequency:
                    </Text>
                
                    <InputSelector fontWeight="normal" size="14px" color="#51315D" onChange={(event) => {selectShowFrequency(event);} } value={survey.showFrequency}>
                        <option value={EnumSurveyShowFrequency.EVERYTIME}>Every time a user opens the app</option>
                        <option value={EnumSurveyShowFrequency.HOURS24}>{EnumSurveyShowFrequency.HOURS24} after user has seen the survey</option>
                        <option value={EnumSurveyShowFrequency.HOURS48}>{EnumSurveyShowFrequency.HOURS48} after user has seen the survey</option>
                    </InputSelector>
                
                    <ErrorText>
                        {showFrequencyError}
                    </ErrorText>

                    <br/>
               
                </FlexContainer>
            </>
        );
    }
}

export default EditForm;