export const DEFAULT_FORM_STATE = {
    imageUrl: null,
    type: "general",
    allEmployeesInPerk: false,
    allClientsInPerk: false,
    selectedCompanyRecipients: [],
    startDate: null,
    endDate: null,
    companyName: null,
    title: null,
    description: null,
    website: null,
    promoCode: null,
    promoCodeImageUrl: null
};

export const PERK_TYPE = {
    GENERAL: "general",
    GIFT: "gift"
};

export const PROMO_CODE_TYPE = {
    TEXT: "text",
    IMAGE: "image"
};

export const USER_GROUP = {
    EMPLOYEES: "employees",
    CLIENTS: "clients",
    BOTH: "both"
};